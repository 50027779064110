import React from 'react';

import {
    Container,
    Row,
    Col,
    Icon,
    LinkList,
    LinkListItem,
    Form,
    Label,
    Input,
    Button,
} from 'design-react-kit';


export function Footer(props) {
    return (
        <footer className="it-footer">
            <div className="it-footer-main theme-darcka">
                <Container tag="div">
                    <section>
                        <Row
                            className="clearfix"
                            tag="div"
                            widths={[
                                'xs',
                                'sm',
                                'md',
                                'lg',
                                'xl'
                            ]}
                        >
                            <Col
                                sm={12}
                                tag="div"
                                widths={[
                                    'xs',
                                    'sm',
                                    'md',
                                    'lg',
                                    'xl'
                                ]}
                            >
                                <div className="it-brand-wrapper">
                                    <a
                                        className=""
                                        href="#"
                                    >
                                        <Icon
                                            color=""
                                            icon="it-pa"
                                            padding={false}
                                            size=""
                                        />
                                        <div className="it-brand-text">
                                            <h2 className="no_toc">
                                                ACEC
                                            </h2>
                                            <h3 className="no_toc d-none d-md-block">
                                                Progetto ACEC
                                            </h3>
                                        </div>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </section>
                    <section className="py-4 border-white border-top">
                        <Row
                            tag="div"
                            widths={[
                                'xs',
                                'sm',
                                'md',
                                'lg',
                                'xl'
                            ]}
                        >
                            
                            <Col
                                className="pb-2"
                                sm={12}
                                tag="div"
                                widths={[
                                    'xs',
                                    'sm',
                                    'md',
                                    'lg',
                                    'xl'
                                ]}
                            >
                                <h4>
                                    <a
                                        href="#"
                                        title="Vai alla pagina: Contatti"
                                    >
                                        Contatti
              </a>
                                </h4>
                                <p>
                                    <strong>
                                        Progetto ACEC
                                    </strong>
                                    <br />
                                    Via Columbia 2, 00133 Roma – Italia
                                    </p>
                                <LinkList
                                    className="footer-list clearfix"
                                    tag="div"
                                >
                                    <LinkListItem
                                        href="#"
                                        tag="a"
                                        title="Vai alla pagina: Posta Elettronica Certificata"
                                    >
                                        Posta Elettronica Certificata
              </LinkListItem>
                                    <LinkListItem
                                        href="#"
                                        tag="a"
                                        title="Vai alla pagina: URP - Ufficio Relazioni con il Pubblico"
                                    >
                                        URP - Ufficio Relazioni con il Pubblico
              </LinkListItem>
                                </LinkList>
                            </Col>
                        </Row>
                    </section>
                </Container>
            </div>
            <div className="it-footer-small-prints clearfix">
                <Container tag="div">
                    <h3 className="sr-only">
                        Sezione Link Utili
      </h3>
                    <ul className="it-footer-small-prints-list list-inline mb-0 d-flex flex-column flex-md-row">
                        <li className="list-inline-item">
                            <a
                                href="#"
                                title="Note Legali"
                            >
                                Media policy
          </a>
                        </li>
                        <li className="list-inline-item">
                            <a
                                href="#"
                                title="Note Legali"
                            >
                                Note legali
          </a>
                        </li>
                        <li className="list-inline-item">
                            <a
                                href="#"
                                title="Privacy-Cookies"
                            >
                                Privacy policy
          </a>
                        </li>
                        <li className="list-inline-item">
                            <a
                                href="#"
                                title="Mappa del sito"
                            >
                                Mappa del sito
          </a>
                        </li>
                    </ul>
                </Container>
            </div>
        </footer>
    );
}