
import contr_tem_det_1 from './resources/contr_tem_det/1. Posso rinnovare il contratto a tempo determinato.pdf';
import contr_tem_det_2 from './resources/contr_tem_det/2. Posso prorogare il contratto il contratto a tempo determinato.pdf';
import licenz_1 from './resources/licenz/1.Se l’azienda a causa del COVID-19 si trova in situazione di fragilità economica, questa può essere causa di licenziamento.pdf';
import licenz_2 from './resources/licenz/2. Quali sono le norme che vietano il licenziamento.pdf';
import licenz_3 from './resources/licenz/3. Quali sono le conseguenze se il datore di lavoro viola il divieto.pdf';
import licenz_4 from './resources/licenz/4. Fino a quanto durerà il divieto di licenziamento.pdf';
import agev_tur_cul_edi_1 from './resources/agev_tur_cul_edi/1. Se possiedo un’attività nel settore turistico (es. alberghi, B&B), devo pagare l’IMU.pdf';
import agev_tur_cul_edi_2 from './resources/agev_tur_cul_edi/2. Se la mia attività si trova nel settore “cultura”, ho diritto a fondi o agevolazioni.pdf';
import agev_tur_cul_edi_3 from './resources/agev_tur_cul_edi/3. Se possiedo un’edicola, ho diritto ad incentivi sulle rese dei giornali.pdf';
import agev_tur_cul_edi_4 from './resources/agev_tur_cul_edi/4. Se sono un’impresa editrice, posso ottenere un credito d’imposta per l’acquisto della carta dei giornali.pdf';
import agev_tur_cul_edi_5 from './resources/agev_tur_cul_edi/5. Se sono un’impresa editrice, posso ottenere un credito d’imposta per l’acquisto di servizi digitali.pdf';
import aiuti_pic_med_imp_1 from './resources/aiuti_pic_med_imp/1. Sono previste garanzie statali sui finanziamenti.pdf';
import aiuti_pic_med_imp_2 from './resources/aiuti_pic_med_imp/2. Posso richiedere dei finanziamenti con tassi di interesse agevolati.pdf';
import aiuti_pic_med_imp_3 from './resources/aiuti_pic_med_imp/3. Se mi trovo in periodo di difficoltà economica, sono previsti degli aiuti.pdf';
import aiuti_pic_med_imp_4 from './resources/aiuti_pic_med_imp/4. Ho diritto al credito d’imposta per i canoni di locazione degli immobili a uso non abitativo e affitto d’azienda.pdf';
import aiuti_pic_med_imp_5 from './resources/aiuti_pic_med_imp/5. Sono tenuto al pagamento dell’IRAP nell’anno 2020.pdf';


export const CATEGORIE = [
    {
        id: 1,
        nome: 'Individuo/Famiglia',
    },
    {
        id: 2,
        nome: 'Impresa/lavoratore autonomo',
    },
];

export const AREE = [
    {
        id: 1,
        nome: 'Contratti a tempo determinato',
    },
    {
        id: 2,
        nome: 'Licenziamenti',
    },
    {
        id: 3,
        nome: 'Agevolazioni per il turismo, cultura ed editoria',
    },
    {
        id: 4,
        nome: 'Aiuti alle piccole e medie imprese',
    },
];

export const DOC = [
    {
        id: 0,
        categoria: 1,
        area: 1,
        desc: null,
        faq: [{
            id: 0,
            question: 'Posso rinnovare il contratto a tempo determinato?',
            answer: null,
            link: contr_tem_det_1
        },{
            id: 1,
            question: 'Posso prorogare il contratto il contratto a tempo determinato?',
            answer: null,
            link: contr_tem_det_2
        },
        ]
    },
    {
        id: 1,
        categoria: 1,
        area: 2,
        desc: null,
        faq: [{
            id: 0,
            question: 'Se l’azienda a causa del COVID-19 si trova in situazione di fragilità economica, questa può essere causa di licenziamento?',
            answer: null,
            link: licenz_1
        },{
            id: 1,
            question: 'Quali sono le norme che vietano il licenziamento?',
            answer: null,
            link: licenz_2
        },{
            id: 2,
            question: 'Quali sono le conseguenze se il datore di lavoro viola il divieto?',
            answer: null,
            link: licenz_3
        },{
            id: 3,
            question: 'Fino a quanto durerà il divieto di licenziamento?',
            answer: null,
            link: licenz_4
        },
        ]
    },
    {
        id: 2,
        categoria: 2,
        area: 3,
        desc: null,
        faq: [{
            id: 0,
            question: 'Se possiedo un’attività nel settore turistico (es. alberghi, B&B), devo pagare l’IMU?',
            answer: null,
            link: agev_tur_cul_edi_1
        },{
            id: 1,
            question: 'Se la mia attività si trova nel settore “cultura”, ho diritto a fondi o agevolazioni?',
            answer: null,
            link: agev_tur_cul_edi_2
        },{
            id: 2,
            question: 'Se possiedo un’edicola, ho diritto ad incentivi sulle rese dei giornali?',
            answer: null,
            link: agev_tur_cul_edi_3
        },{
            id: 3,
            question: 'Se sono un’impresa editrice, posso ottenere un credito d’imposta per l’acquisto della carta dei giornali?',
            answer: null,
            link: agev_tur_cul_edi_4
        },{
            id: 4,
            question: 'Se sono un’impresa editrice, posso ottenere un credito d’imposta per l’acquisto di servizi digitali?',
            answer: null,
            link: agev_tur_cul_edi_5
        },
        ]
    },
    {
        id: 3,
        categoria: 2,
        area: 4,
        desc: null,
        faq: [{
            id: 0,
            question: 'Sono previste garanzie statali sui finanziamenti?',
            answer: null,
            link: aiuti_pic_med_imp_1
        },{
            id: 1,
            question: 'Posso richiedere dei finanziamenti con tassi di interesse agevolati?',
            answer: null,
            link: aiuti_pic_med_imp_2
        },{
            id: 2,
            question: 'Se mi trovo in periodo di difficoltà economica, sono previsti degli aiuti?',
            answer: null,
            link: aiuti_pic_med_imp_3
        },{
            id: 3,
            question: 'Ho diritto al credito d’imposta per i canoni di locazione degli immobili a uso non abitativo e affitto d’azienda?',
            answer: null,
            link: aiuti_pic_med_imp_4
        },{
            id: 4,
            question: 'Sono tenuto al pagamento dell’IRAP nell’anno 2020?',
            answer: null,
            link: aiuti_pic_med_imp_5
        },
        ]
    },
];