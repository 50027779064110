import React from 'react';
import ReactDOM from 'react-dom';
import './app.scss'

import App from './App';
import * as serviceWorker from './serviceWorker';

import 'popper.js/dist/popper.min.js';

/*
import 'jquery/src/jquery';

import $ from 'jquery';

import "bootstrap-select/dist/js/bootstrap-select.min.js"


import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/js/bootstrap.bundle.js'
import "bootstrap-italia/dist/js/bootstrap-italia.min.js"

$.fn.selectpicker.Constructor.BootstrapVersion = '4';*/

ReactDOM.render(
  <React.Fragment>
    <App />
  </React.Fragment>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
