import React from 'react'


export function ErrorPage() {
    return (
        <React.Fragment>
            <div className="container">
                <div className="row">
                    <div class="col-md-12">
                        <div className="error-template">
                            <h1>
                                Oops!</h1>
                            <h2>
                                404 Not Found</h2>
                            <div className="error-details">
                                Spiacenti, La pagina richesta non è stata trovata!
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
}