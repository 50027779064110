import React, { useState } from "react";
import {
  Link,
  useLocation,
} from "react-router-dom";

import { Header, HeaderContent, HeaderToggler, Collapse, Icon, Nav, NavItem } from "design-react-kit";

export function NavHeader(props) {


  let firstId = 1;

  const location = useLocation();

  if (location !== null || location !== undefined) {
    const menu = props.menu.find(i => i.path === location.pathname);
    if (menu !== null && menu !== undefined) {
      firstId = menu.id;
    }
  } 

  const [expanded, setExpaded] = useState(false);
  const [currentMenuId, setCurrentMenuId] = useState(firstId);

  function onSetExpanted() {
    setExpaded(!expanded);
  }

  function createMenu() {
    return (
      props.menu.map(i => {
        return (
          <NavItem key={i.id} tag="li" onClick={() => { setCurrentMenuId(i.id); onSetExpanted(); }} className={currentMenuId === i.id ? 'active' : null } >
            <Link className={currentMenuId === i.id ? 'nav-link active' : 'nav-link'} to={i.path} >{i.menu}</Link>
          </NavItem>
        );
      })
    );
  }

  return (
    <Header
      small={false}
      theme={props.theme}
      type="navbar"
    >
      <HeaderContent
        expand="lg"
      >
        <HeaderToggler
          aria-controls="nav1"
          aria-expanded="false"
          aria-label="Toggle navigation"
          data-toggle="collapse" 
          data-target="#nav1" 
          onClick={onSetExpanted}
        >
          <Icon
            color=""
            icon="it-burger"
            padding={false}
            size=""
          />
        </HeaderToggler>
        <Collapse
          appear={false}
          enter
          exit
          header
          in={false}
          isOpen={expanded}
          mountOnEnter={false}
          navbar
          id="nav1"
          //onEnter={function noRefCheck() { }}
          //onEntered={function noRefCheck() { }}
          //onEntering={function noRefCheck() { }}
          //onExit={onSetExpanted}
          //onExited={function noRefCheck() { }}
          //onExiting={function noRefCheck() { }}
          onOverlayClick={onSetExpanted}
          tag="div"
          timeout={350}
          //unmountOnExit={false}
        >
          <div className="menu-wrapper">
            <Nav
              navbar
              tag="ul"
              vertical={false}
              theme={props.theme}
            >
              {createMenu()}
            </Nav>
          </div>
        </Collapse>
      </HeaderContent>
    </Header>
  );
}