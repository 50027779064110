import React from 'react';
import './App.css';

import { Headers } from "design-react-kit"

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import { CenterHeader } from './component/center-header';
import { NavHeader } from './component/nav-header';
import { ChiSiamo } from './page/chi-siamo';
import { AreeTematiche } from './page/aree-tematiche';
import { Team } from './page/team';
import { Footer } from './component/footer';
import { ErrorPage } from './page/error-page';


const theme = 'dark';

//const home = '/';
const chi_siamo = '/';
const aree_tematiche = '/aree-tematiche';
const team = '/team';
//const contatti = '/contatti';

export const MENU = [
  //{
  //  id: 0,
  //  path: home,
  //  menu: 'Home'
  //},
  {
    id: 1,
    path: chi_siamo,
    menu: 'Chi siamo'
  },
  {
    id: 2,
    path: aree_tematiche,
    menu: 'Aree tematiche'
  },
  {
    id: 3,
    path: team,
    menu: 'Team'
  },
  //{
  //  id: 4,
  //  path: contatti,
  //  menu: 'Contatti'
  //},
];

function App() {

  return (
    <Router>
      <Headers shadow={false} sticky={false}>
        <div className="it-nav-wrapper">
          <CenterHeader theme={theme} />
          <NavHeader theme={theme} menu={MENU} />
        </div>
      </Headers>
      <main>
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <Switch>
              <Route exact path={chi_siamo}>
                <ChiSiamo />
              </Route>
              <Route path={aree_tematiche}>
                <AreeTematiche />
              </Route>
              <Route path={team}>
                <Team />
              </Route>
              <Route path="*">
                <ErrorPage />
              </Route>
            </Switch>
          </div>
          <div className="col-md-2"></div>
        </div>
      </main>
      <Footer theme={theme}/>
    </Router >
  );
}

export default App;
