import React, { useState } from "react";

import { Button, LinkList, LinkListItem, Icon, Accordion, AccordionHeader, AccordionBody } from 'design-react-kit';

import { DOC, CATEGORIE, AREE } from '../document'

export function ChiSiamo() {
    const [selectedId, setSelectedid] = useState();
    const [currentAreaIds, setCurrentAreaIds] = useState([]);
    

    function onTogleAccordion(areaId) {
        const index = currentAreaIds.indexOf(areaId);
        const tempAr = [...currentAreaIds];
        if (index === -1) {
            tempAr.push(areaId)
            setCurrentAreaIds(tempAr);
        } else {
            tempAr.splice(index, 1)
            setCurrentAreaIds(tempAr);
        }
    }

    function creaListaFaq(area) {
        return (
            <LinkList multiline tag="div">
                {
                    area.faq.map(faq => {
                            return (
                                <LinkListItem
                                    key={faq.id}
                                    className="right-icon"
                                    tag="a"
                                    href={faq.link} target="_blank">
                                    <span>{faq.id + 1}. {faq.question}</span>
                                    <i className="it-chevron-right right" />
                                    <Icon
                                        className="icon-right"
                                        color="primary"
                                        icon="it-chevron-right"
                                        padding={false}
                                        size=""
                                        style={{
                                            ariaHidden: true
                                        }}
                                    />
                                </LinkListItem>
                            );
                        })
                }
            </LinkList>
        );
    }

    function creaListaCategoria() {
        return (
            DOC.filter(doc => doc.categoria === selectedId)
                .map(area => {
                    const currentArea = AREE.find(a => a.id === area.area);
                    return (
                        <Accordion tag="div" key={area.id}>
                            <AccordionHeader
                                onToggle={() => {onTogleAccordion(area.id) }}
                                active={currentAreaIds.includes(area.id)}
                                tag="button"
                            >
                                {currentArea.nome}
                            </AccordionHeader>
                            <AccordionBody
                                active={currentAreaIds.includes(area.id)}
                                timeout={350}
                            >
                              {creaListaFaq(area)}  
                        </AccordionBody>
                    </Accordion>
                    );
                })
        )
    }

    return (
        <React.Fragment>
            <h3 className="p-4">Chi siamo</h3>
            <div className="p-4">
                <p>
                    Il Covid 19, come noto, ha causato non solo una gravissima crisi sanitaria ma ha fatto esplodere una
                    pesantissima emergenza economica che colpisce tutti i settori della nostra società.
                    I problemi che colpiscono imprese, lavoratori autonomi e famiglie sono pesantissimi.
                </p>
                <p>La risposta delle Autorità passa, necessariamente, attraverso provvedimenti
                    di legge volti a stimolare l’iniziativa ed a fornire strumenti di supporto.</p>
                <p>
                    <b>Il Dipartimento di Management e Diritto</b>, all’interno dell’Università di Tor Vergata,
                    è il contesto dove si concentrano le competenze di ricerca inerenti la gestione delle imprese,
                    l’analisi finanziaria ed il credito e le aree del diritto che disciplinano la vita economica di persone ed imprese.
                </p>
                <p>
                    Al DMD fanno riferimento docenti specializzati nel credito alle famiglie e
                    credito alle imprese che già all’interno del Dottorato in Banking and Finance hanno sviluppato laboratori
                    atti a trasmettere le conoscenze su questi ambiti; vi sono poi studiosi d’impresa, di diritto tributario,
                    del lavoro ovvero di tutte le aree che rilevano per le tematiche toccate dai provvedimenti di rilancio.
                </p>
            </div>
            <div className="text-center mt-1 mb-3">
            {
                CATEGORIE.map(categoria => 
                    {
                        return (<Button
                                    key={categoria.id}
                                    color="primary"
                                    icon={false}
                                    outline
                                    tag="button"
                                    onClick={() => setSelectedid(categoria.id)}
                                    className="m-3"
                                >
                                    {categoria.nome}
                                </Button>);
                        })
            }
            </div>
            <div  className="mt-2 mb-5">
                {creaListaCategoria()}
            </div>
        </React.Fragment>
    );
}