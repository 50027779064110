import React from 'react';
import { Header, HeaderContent, HeaderBrand, HeaderRightZone, HeaderSocialsZone, Icon } from "design-react-kit"



export function CenterHeader(props) {
  return (
    <Header
      small={false}
      theme={props.theme}
      type="center"
    >

      <HeaderContent>
        <HeaderBrand
          iconName="it-code-circle"
          responsive={true}
          tag="a"
        >
          <h2>
            ACEC
      </h2>
          <h3>
            Progetto ACEC
      </h3>
        </HeaderBrand>
        <HeaderRightZone>
          <HeaderSocialsZone label="Seguici su">
            <ul>
              <li>
                <a
                  aria-label="Facebook"
                  href="#"
                  target="_blank"
                >
                  <Icon
                    color=""
                    icon="it-facebook"
                    padding={false}
                    size=""
                  />
                </a>
              </li>
              <li>
                <a
                  aria-label="Github"
                  href="#"
                  target="_blank"
                >
                  <Icon
                    color=""
                    icon="it-github"
                    padding={false}
                    size=""
                  />
                </a>
              </li>
              <li>
                <a
                  aria-label="Twitter"
                  href="#"
                  target="_blank"
                >
                  <Icon
                    color=""
                    icon="it-twitter"
                    padding={false}
                    size=""
                  />
                </a>
              </li>
            </ul>
          </HeaderSocialsZone>
        </HeaderRightZone>
      </HeaderContent>
    </Header>
  );
}
