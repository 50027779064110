import React, { useState } from "react";

import { Button, LinkList, LinkListItem, Icon, Accordion, AccordionHeader, AccordionBody } from 'design-react-kit';

import { DOC, AREE } from '../document'


export function AreeTematiche() {
    const documenti = DOC;
    const [currentAreaIds, setCurrentAreaIds] = useState([]);
    

    function onTogleAccordion(areaId) {
        const index = currentAreaIds.indexOf(areaId);
        const tempAr = [...currentAreaIds];
        if (index === -1) {
            tempAr.push(areaId)
            setCurrentAreaIds(tempAr);
        } else {
            tempAr.splice(index, 1)
            setCurrentAreaIds(tempAr);
        }
    }

    function creaListaFaq(areaId) {
        const docArea = documenti.find(doc => doc.area === areaId);
        return (

            <LinkList multiline tag="div">
                {
                    docArea.faq.map(faq => {
                            return (
                                <LinkListItem
                                    key={faq.id}
                                    className="right-icon"
                                    tag="a"
                                    href={faq.link} target="_blank">
                                    <span>{faq.id + 1}. {faq.question}</span>
                                    <i className="it-chevron-right right" />
                                    <Icon
                                        className="icon-right"
                                        color="primary"
                                        icon="it-chevron-right"
                                        padding={false}
                                        size=""
                                        style={{
                                            ariaHidden: true
                                        }}
                                    />
                                </LinkListItem>
                            );
                        })
                }
            </LinkList>
        );
    }

    function creaListaArea() {
        return (
            AREE.map(area => 
                {   
                    return (
                        <Accordion tag="div" key={area.id}>
                            <AccordionHeader
                                onToggle={() => {onTogleAccordion(area.id) }}
                                active={currentAreaIds.includes(area.id)}
                                tag="button"
                            >
                                {area.nome}
                            </AccordionHeader>
                            <AccordionBody
                                active={currentAreaIds.includes(area.id)}
                                timeout={350}
                            >
                              {creaListaFaq(area.id)}  
                        </AccordionBody>
                    </Accordion>
                    );
                })
        )
    }

    return (
        <React.Fragment>
            <h3 className="p-4">Aree tematiche</h3>
            <div className="p-4">
                <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris  ...
                </p>
            </div>
            
            <div  className="mt-2 mb-5">
                {creaListaArea()}
            </div>
        </React.Fragment>
    );
}