import React from "react";

import {
    Card,
    CardBody,
    CardTitle,
    CardText,
    AvatarWrapper,
    AvatarIcon,
    AvatarExtraText,
} from 'design-react-kit';

export function Team() {
    return (
        <React.Fragment>
            <h3 className="p-4">Team</h3>
            <div className="row">
                <div className="col-12">
                    <Card
                        noWrapper={false}
                        tag="div">
                        <CardBody tag="div">
                            <CardTitle tag="h5">
                                Ricercatori
                            </CardTitle>
                            <CardText tag="div">
                                <span >
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </span>
                                <div className="row text-left mt-3">
                                    <AvatarWrapper
                                        extra="text"
                                        tag="div">
                                        <AvatarIcon
                                            size="xl"
                                            tag="div">
                                            <img
                                                alt="Xenia Scimone"
                                                src="https://randomuser.me/api/portraits/women/33.jpg"
                                            />
                                        </AvatarIcon>
                                        <AvatarExtraText tag="div">
                                            <h4>
                                                Xenia Scimone
                                            </h4>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed...
                                            </p>
                                        </AvatarExtraText>
                                    </AvatarWrapper>
                                </div>
                                <div className="row text-left">
                                    <AvatarWrapper
                                        extra="text"
                                        tag="div">
                                        <AvatarIcon
                                            size="xl"
                                            tag="div">
                                            <img
                                                alt="Umberto Filotto"
                                                src="https://randomuser.me/api/portraits/men/33.jpg"
                                            />
                                        </AvatarIcon>
                                        <AvatarExtraText tag="div">
                                            <h4>
                                                Umberto Filotto
                                            </h4>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed...
                                            </p>
                                        </AvatarExtraText>
                                    </AvatarWrapper>
                                </div>
                            </CardText>
                        </CardBody>
                    </Card>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Card
                        noWrapper={false}
                        tag="div">
                        <CardBody tag="div">
                            <CardTitle tag="h5">
                                Esperti
                            </CardTitle>
                            <CardText tag="div">
                                <span>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </span>
                                <div className="row text-left mt-3">                                    
                                    <AvatarWrapper
                                        extra="text"
                                        tag="div">
                                        <AvatarIcon
                                            size="xl"
                                            tag="div">
                                            <img
                                                alt="Maria Cristina Cataudella"
                                                src="https://randomuser.me/api/portraits/women/13.jpg"
                                            />
                                        </AvatarIcon>
                                        <AvatarExtraText tag="div">
                                            <h4>
                                                Maria Cristina Cataudella
                                            </h4>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed...
                                            </p>
                                        </AvatarExtraText>
                                    </AvatarWrapper>
                                </div>
                                <div className="row text-left">   
                                    <AvatarWrapper
                                        extra="text"
                                        tag="div">
                                        <AvatarIcon
                                            size="xl"
                                            tag="div">
                                            <img
                                                alt="Vincenzo Ficari"
                                                src="https://randomuser.me/api/portraits/men/13.jpg"
                                            />
                                        </AvatarIcon>
                                        <AvatarExtraText tag="div">
                                            <h4>
                                                Vincenzo Ficari
                                            </h4>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed...
                                            </p>
                                        </AvatarExtraText>
                                    </AvatarWrapper>
                                </div>
                                <div className="row text-left"> 
                                    <AvatarWrapper
                                        extra="text"
                                        tag="div">
                                        <AvatarIcon
                                            size="xl"
                                            tag="div">
                                            <img
                                                alt="Vincenzo Farina"
                                                src="https://randomuser.me/api/portraits/men/17.jpg"
                                            />
                                        </AvatarIcon>
                                        <AvatarExtraText tag="div">
                                            <h4>
                                                Vincenzo Farina
                                            </h4>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed...
                                            </p>
                                        </AvatarExtraText>
                                    </AvatarWrapper>
                                </div>
                            </CardText>
                        </CardBody>
                    </Card>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <Card
                        noWrapper={false}
                        tag="div">
                        <CardBody tag="div">
                            <CardTitle tag="h5">
                                Team Operativo
                            </CardTitle>
                            <CardText tag="div">
                                <span>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </span>
                                <div className="row text-left mt-3"> 
                                    <AvatarWrapper
                                        extra="text"
                                        tag="div">
                                        <AvatarIcon
                                            size="xl"
                                            tag="div">
                                            <img
                                                alt="Paolo Iervolino"
                                                src="https://randomuser.me/api/portraits/men/21.jpg"
                                            />
                                        </AvatarIcon>
                                        <AvatarExtraText tag="div">
                                            <h4>
                                                Paolo Iervolino
                                            </h4>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed...
                                            </p>
                                        </AvatarExtraText>
                                    </AvatarWrapper>
                                </div>
                                <div className="row text-left"> 
                                    <AvatarWrapper
                                        extra="text"
                                        tag="div">
                                        <AvatarIcon
                                            size="xl"
                                            tag="div">
                                            <img
                                                alt="Grazia Maggi"
                                                src="https://randomuser.me/api/portraits/women/28.jpg"
                                            />
                                        </AvatarIcon>
                                        <AvatarExtraText tag="div">
                                            <h4>
                                                Grazia Maggi
                                            </h4>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed...
                                            </p>
                                        </AvatarExtraText>
                                    </AvatarWrapper>
                                </div>
                                <div className="row text-left"> 
                                    <AvatarWrapper
                                        extra="text"
                                        tag="div">
                                        <AvatarIcon
                                            size="xl"
                                            tag="div">
                                            <img
                                                alt="Erika Bragaglia"
                                                src="https://randomuser.me/api/portraits/women/17.jpg"
                                            />
                                        </AvatarIcon>
                                        <AvatarExtraText tag="div">
                                            <h4>
                                                Erika Bragaglia
                                            </h4>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed...
                                            </p>
                                        </AvatarExtraText>
                                    </AvatarWrapper>
                                </div>
                                <div className="row text-left"> 
                                    <AvatarWrapper
                                        extra="text"
                                        tag="div">
                                        <AvatarIcon
                                            size="xl"
                                            tag="div">
                                            <img
                                                alt="Gianpaolo Stella"
                                                src="https://randomuser.me/api/portraits/men/10.jpg"
                                            />
                                        </AvatarIcon>
                                        <AvatarExtraText tag="div">
                                            <h4>
                                                Gianpaolo Stella
                                            </h4>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed...
                                            </p>
                                        </AvatarExtraText>
                                    </AvatarWrapper>
                                </div>
                                <div className="row text-left"> 
                                    <AvatarWrapper
                                        extra="text"
                                        tag="div">
                                        <AvatarIcon
                                            size="xl"
                                            tag="div">
                                            <img
                                                alt="Luca Bellardini"
                                                src="https://randomuser.me/api/portraits/men/22.jpg"
                                            />
                                        </AvatarIcon>
                                        <AvatarExtraText tag="div">
                                            <h4>
                                                Luca Bellardini
                                            </h4>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed...
                                            </p>
                                        </AvatarExtraText>
                                    </AvatarWrapper>
                                </div>
                            </CardText>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </React.Fragment>
    );
}